import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "videojs-youtube";
import "video.js/dist/video-js.css";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
import "@silvermine/videojs-quality-selector";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Define initial options for the video player
const initialOptions = {
  controls: true,
  fluid: true,
  enableDocumentPictureInPicture: true,
  enableSmoothSeeking: true,
  controlBar: {
    volumePanel: { inline: false },
    children: [
      "playToggle",
      "progressControl",
      "volumePanel",
      "currentTimeDisplay",
      "timeDivider",
      "durationDisplay",
      "liveDisplay",
      "playbackRateMenuButton",
      "chaptersButton",
      "fullscreenToggle",
    ],
  },
};

window.videoDuration = 0;

const VideoPlayer = ({ source, showFloatVideo }) => {
  const videoNode = useRef(null);
  const player = useRef(null);
  const [source1, setSource] = useState(source);
  const [videoLoader, setVideoLoader] = useState(false);
  const [isFirstPlay, setIsFirstPlay] = useState(true);

  // Update the source if the `source` prop changes
  useEffect(() => {
    if (source.src !== source1.src) {
      setSource(source);
    }
  }, [source, source1.src]);

  // Initialize and configure the video player
  useEffect(() => {
    if (videoNode.current) {
      setVideoLoader(true);
      const videoJsOptions = {
        sources: [source1],
        playbackRates: [0.5, 1, 1.5, 2],
        preload : 'auto',
      };

      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...videoJsOptions,
      });

      if (!player.current) return;

      player.current.ready(() => {
        if (window.videoDuration) {
          player.current.currentTime(window.videoDuration);
          player.current.play();
        }
        setVideoLoader(false);
      });

      // Event listeners
      player.current.on("timeupdate", () => {
        window.videoDuration = player.current.currentTime();
      });

      player.current.on("play", () => {
        if (isFirstPlay) setIsFirstPlay(false);
      });

      // Fullscreen mode handling
      player.current.on("fullscreenchange", () => {
        if (player.current.isFullscreen()) {
          document.body.classList.add("video_fullscreen");
          document.body.style.overflow = "hidden";
        } else {
          document.body.classList.remove("video_fullscreen");
          document.body.style.overflow = "auto";
        }
      });

      // Control bar visibility handling
      const controlBarData = player.current?.getChild("controlBar");

      const handleMouseEnter = () => controlBarData?.show();
      const handleMouseLeave = () => controlBarData?.hide();

      const handleTouchStart = () => {
        controlBarData?.show();
        clearTimeout(player.current.controlsTimeout);
      };

      const handleTouchEnd = () => {
        player.current.controlsTimeout = setTimeout(() => controlBarData?.hide(), 3000);
      };

      player.current.on("mouseenter", handleMouseEnter);
      player.current.on("mouseleave", handleMouseLeave);
      player.current.on("touchstart", handleTouchStart);
      player.current.on("touchend", handleTouchEnd);

      setTimeout(() => controlBarData?.hide(), 2000);
    }

    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, [source1]);

  const customLoadingIcon = (
    <LoadingOutlined style={{ fontSize: 40, color: "#fff" }} spin />
  );

  // function getYouTubeVideoId(url) {
  //   const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
  //   const match = url.match(regex);
  //   return match ? match[1] : null;
  // }
  // const videoId = getYouTubeVideoId(source.src);
  // const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  // // console.log(thumbnailUrl, 'thumbnailUrl');
  

  // useEffect(() => {
  //   const iframe = document.getElementById('vimeo-video');
  //   // console.log(iframe, 'iframe');
    
  //   if (iframe) {
  //     // Create the new overlay div
  //     const newDiv = document.createElement('div');
  //     newDiv.classList.add('cm_overlayDv');
  //     newDiv.style.backgroundImage = `url(${thumbnailUrl})`; // Example styling
  //     newDiv.style.position = 'absolute'; // Make it float over the iframe
  //     newDiv.style.top = 0;
  //     newDiv.style.left = 0;
  //     newDiv.style.width = '100%'; // Make the overlay cover the iframe
  //     newDiv.style.height = '100%';
  //     newDiv.style.zIndex = 10; // Make sure it's on top of the iframe
  
  //     // Position the iframe container relatively to allow positioning of overlay
  //     iframe.style.position = 'relative';
  
  //     // Append the overlay div right before the iframe in the DOM
  //     iframe.appendChild(newDiv);

  //     // Remove the overlay when the video plays
  //     player.current.on("play", () => {
  //       if (newDiv) {
  //         newDiv.remove(); // Remove overlay when video starts playing
  //       }
  //     });
  //   }
  // }, [thumbnailUrl]);

  return (
    <div className={`App ${isFirstPlay ? "pointer_all" : "pointer_none"}`} style={{ position: "relative" }}>
      <div className="innerPointer_yt_top"></div>
      <video
        ref={videoNode}
        id="vimeo-video"
        className="video-js"
        controls
        // data-setup='{ "loop": "true", "preload": "true" }'
      >
        </video>
        {/* <div className="cm_overlayDv" style={{backgroundImage:`url(${thumbnailUrl})`,}}></div> */}
      
      {videoLoader && (
        <div className="spinner_yt">
          <Spin size="large" indicator={customLoadingIcon} />
        </div>
      )}
      <div className="innerPointer_yt_bottom"></div>
    </div>
  );
};

export default VideoPlayer;
