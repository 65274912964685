import  { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import svg from '../components/svg'
import { setMyState, common, NoDataWrapper, dataLoader } from '../components/Comman'
import { appStore } from '../zustand/store'
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';


const Dashboard = () => {
    const slugName = window.location.href.split('/')[4];

    let myStore = appStore(state => state);
    let userData = myStore.userData;
    const memberShipUser = userData.MembershipUser;
    let appData = myStore.appData;
    let brandSetting = myStore.brandSetting;
    // console.log(memberShipUser,"memberShipUser");
    let tokenCookie = Cookies.get(`access_token_${slugName}`)
    ? Cookies.get(`access_token_${slugName}`)
    : false;

    const navigator = useNavigate();
    
    const [state, setQuery] = useState({
        funnel_id: memberShipUser?.funnel_id,
        member_id: memberShipUser?.id,
        funnel_page_id: memberShipUser?.funnel_page_id,
        token: tokenCookie,
        courseData: {},
        lessionList: {},
        banner_img: '',
        updated_at : '',
        themeColor : brandSetting.theme_color1,
        colorIndex : null,
        // themeColor2 : brandSetting.themeColor2,
        buttonColor: brandSetting.theme_color1,
        banner_img_overlaycolor : '',
        page_title : ''
    })
    let getColor1 =(index)=>{
        setMyState(setQuery,{
            ...state,
            // themeColor : brandSetting.theme_color2,
            colorIndex : index,
        })
        
    }
    let getColor2 =(index)=>{
        setMyState(setQuery,{
            ...state,
            colorIndex : null,
            // themeColor : brandSetting.theme_color1
        })
    }
    let body = {
        funnel_id: memberShipUser?.funnel_id,
        member_id: memberShipUser?.id,
        funnel_page_id: memberShipUser?.funnel_page_id,
        token: tokenCookie,
    };
    useEffect(()=>{
        getCourseListData();
        if (document.body.classList.contains('video_fullscreen')) { 
            document.body.classList.remove('video_fullscreen');
            document.body.style.overflow = 'auto';
       }
    },[myStore.searchList?.searchType])
    
    useEffect(()=>{
        const searchData = {
            funnel_id : appData.funnel_id,
            member_id : userData?.MembershipUser?.id,
            search_name : myStore.searchList?.search_keyword,
            token : tokenCookie,
            search_type :  myStore.searchList?.searchType
        }
        if(myStore.searchList?.apiName=="searchcourselist" && myStore.searchList?.success){
            common.getAPI(
                {
                    method: "Post",
                    url: "searchcourselist",
                    data: searchData,
                },
                async (resp) => {  
                    if(myStore.searchList?.searchType === 'course'){
                        if(resp.courseData != ''){
                            setMyState(setQuery, {
                                ...state,
                                courseData : resp.courseData,
                            })
                        } else{
                            setMyState(setQuery, {
                                ...state,
                                courseData : '',  
                            })
                        }
                    }else{
                        let lessonList = resp.courseData != '' ? resp.courseData : [];
                       myStore.updateStoreData("searchLessonData" , lessonList)
                    }
                   
                }
            );
            let data ={
                ...myStore.searchList ,
                apiName : "",
                success : false,
            }
            myStore.updateStoreData("searchList" , data)
        }

    },[myStore])

    const getCourseListData=()=>{
        setMyState(setQuery,{  pageLoading : true});
        if(userData.access_token){
            common.getAPI(
                {
                    method: "Post",
                    url: "courselist",
                    data: body,
                },
                async (resp) => {
                    if(resp.courseData != ''){
                        setMyState(setQuery, {
                            ...state,
                            courseData : resp.courseData,
                            banner_img : resp.banner_img,
                            banner_img_overlaycolor : resp.banner_img_overlaycolor,
                            pageLoading : false,
                            page_title : resp.page_title

                        })
                    } 
                    // console.log(resp, 'respBannerImg');
                }
            );
        }
    }
    // const getLessonListData=()=>{       
    //     if(userData.access_token){
    //         common.getAPI(
    //             {
    //                 method: "Post",
    //                 url: "lessonlist",
    //                 data: body,
    //             },
    //             async (resp) => {  
    //                 if(resp.course != ''){
    //                     setMyState(setQuery, {
    //                         ...state,
    //                         lessionList : resp.course,  
    //                     })
    //                 } 
    //             }
    //         );
    //     }
    // }
    
    
    const getSingleCourse=(e, course)=>{
        myStore.updateStoreData("searchLessonData" , []);
        let data ={
          search_keyword: '',
          apiName: '',
          success: true,
          searchType: 'course'
        };
        myStore.updateStoreData("searchList" , data);
        
       const myParam = JSON.stringify({fId :course.funnel_page_id});
       localStorage.setItem('courseName',course?.name)
       myStore.updateStoreData("searchLessonData" , []);
        const encoded=btoa(myParam);
        if(course.lessonCount != 0){
            navigator(`/my-learning?course=${encoded}`);
        }else{
            toast.error("No Lesson Found!")
        }
    }
    return (
        <>
            <Helmet>
                <title>{state.page_title}</title>
            </Helmet>
            
            <div className='mc_pageScroll_dv'>
                {state.banner_img != '' ? 
                <div className='mc_pageBanner py-24 px-3 text-white relative' 
                    // style={{backgroundImage:`url(${state.banner_img})`}}
                >
                    <img src={state.banner_img} alt='Banner' className='mc_bannerimg_tag'/>
                    <div className='mc_overlayBg' style={{backgroundColor: brandSetting.banner_img_overlaycolor}}></div>
                    <div className="max-container relative flex justicy-center items-center">
                        <div className="mx-full w-full text-center">
                            {/* <h2 className="text-3xl font-semibold tracking-tight text-white">My Course</h2>
                            <p className="text-sm tracking-tight text-white mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                        </div>
                    </div>
                </div>
                 : ''} 
                <div className='max-container py-8 '>
                    <div className='grid grid-cols-3 gap-4 mobileGrid'>
                        {state.courseData.length > 0 && !state.pageLoading ? state.courseData.map((items, index)=>{
                            return <div key={index} className='mc_whiteBox_courseList'>
                                <div className="grid grid-cols-1 w-full">
                                    <div className="order-1">
                                        <div className='mc_courseList_items'>
                                            <a href={() => false} onClick={(e)=> getSingleCourse(e, items )}  className='mc_courdeImg' 
                                                // style={{backgroundImage: `url(${items.funnel_page_image})`}}
                                            >
                                                <img src={items.funnel_page_image} alt='course image'/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="order-2">
                                        <div className='mc_courseListContent pt-5'>
                                            <h3 className='text-sm text-black font-bold mb-2'>{items.name}</h3>
                                            <p className='text-sm text-gray-900'>{items.short_description}</p>
                                            <div className='mc_modluldv mt-5 flex flex-row items-center'>
                                                <div className="flex flex-row items-center text-xs">
                                                    <span className='mr-2 mc_spanIcon' style={{fill : `${brandSetting.theme_color2}`, border : `0.5px solid ${brandSetting.theme_color2}` }}>
                                                        {svg.app.fileIcon}
                                                    </span>
                                                    {items.lessonCount} Lesson 
                                                </div>
                                                <div className="flex flex-row items-center ml-12 text-xs">
                                                    <span className='mr-2 mc_spanIcon mc_iconblue' style={{fill : `${brandSetting.theme_color2}`, border : `0.5px solid ${brandSetting.theme_color2}`}}>
                                                        {svg.app.calanderIcon}
                                                    </span>
                                                    {common.dateFormatter(items.enroll_date ? items.enroll_date : items.up, "D, MMM YYYY, hh:ss A")}
                                                </div>
                                            </div>
                                            <div className='mc_btnHolder mt-5 w-40'>
                                                <a href={() => false} onClick={(e)=> getSingleCourse(e, items )} className={`mc_btn mc_courseBtn ${brandSetting.theme_color1}`} style={{color:brandSetting.theme_button_txt_color, fill:brandSetting.theme_button_txt_color, backgroundColor : index == state.colorIndex ?  brandSetting.theme_color2 : brandSetting.theme_color1}}  onMouseEnter={()=> getColor1(index)} onMouseLeave={()=> getColor2(index)}>View Course {svg.app.arrowRight}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }) 
                        : ''
                        
                    }
                    </div>
                    {/* {state.courseData == '' &&
                        <NoDataWrapper 
                            className="group relative bg-white box-shadow rounded-md mc_courseBox" 
                            blockCount="1" 
                            height="440"
                            width="1200" 
                            section="projectLoader"
                            title={'Search result not found. '}                       
                        />
                    } */}
                    {state.pageLoading ? dataLoader.commonLoader : (state.courseData == '' && <NoDataWrapper 
                        className="group relative bg-white box-shadow rounded-md mc_courseBox" 
                        blockCount="1" 
                        height="440"
                        width="1200" 
                        section="projectLoader"
                        title={'Search result not found. '}                       
                    />)
                    }
                </div>
            </div>
        </>
    )
}

export default Dashboard