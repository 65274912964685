'use client';
import React, {useState } from 'react'
import {Helmet} from "react-helmet";

import { cources, sharedSocialUrl, libraryData } from './index';
import { Progress } from 'antd';
import svg from '../components/svg';
import { Link, useNavigate } from 'react-router-dom';
import {NoDataWrapper , setMyState} from '../components/Comman'; 


// import {
//   Tabs,
//   TabsHeader,
//   TabsBody,
//   Tab,
//   TabPanel,
// } from "@material-tailwind/react";

// import { Tabs } from 'antd';
// import { useRouter, usePathname  } from 'next/navigation';




const MyCourses = (props) => {
    // const [activeTab, setActiveTab] = useState(0);

    const navigate = useNavigate();
    console.log(navigate,'navigate');
    // const pathname = window.location.pathname;
    const [activeTab, setActiveTab] = useState("MyLearnings");
    const [innerActiveTab, setInnerActiveTab] = useState("allCourses");

    // const handleTabClick = (index) => {
    //     setActiveTab(index);
    // };
    const data= [
        {
            id:'1',
            label: "My Learnings",
            value: "MyLearnings",
            tabTitle : 'Course In-Progress',
        },
        {
            id:'2',
            label: "Completed Courses",
            value: "CompletedCourses",
            tabTitle : 'Completed Course',
        },
        {
            id:'3',
            label: "Courses Library",
            value: "CoursesLibrary",
            tabTitle : 'Courses Library"',
        },
        {
            id:'4',
            label: "Wishlist",
            value: "Wishlist",
            tabTitle : 'My Wishlist',
        },
    ]
    const coursesData= [
        {
            id: '1',
            label: "All Courses",
            value: "allCourses",
            tabTitle : 'Course In-Progress',
        },
        {
            id: '2',
            label: "Trending Courses",
            value: "TrendingCourses",
            tabTitle : 'Completed Course',
        },
        {
            id: '3',
            label: "Most Purchased",
            value: "MostPurchased",
            tabTitle : '',
        },
        {
            id: '4',
            label: "Most Viewed",
            value: "MostViewed",
            tabTitle : 'My Wishlist',
        },
        {
            id: '5',
            label: "Most Liked",
            value: "MostLiked",
            tabTitle : 'My Wishlist',
        },
    ];

    let [state, setQuery] = useState({
        libraryData :libraryData,
        // whishList : false,
        cources : cources,
        pageLoading : false,
        page : 1,
        limit : 4,
        keyword : '',
        totalRecords :'',
        modules : '6',
        currentModules: '6'
    })

    console.log(state.libraryData.filter((w) => w.whishList === true), 'state.libraryData');
   
    return (
        <>
                
            {/* <Helmet>
                <title>My Courses</title>
            </Helmet> */}
            <div className="max-container">
                <div className="mx-full w-full px-0 py-8 sm:px-0 sm:py-8 lg:px-0">
                    <h2 className="text-3xl font-semibold tracking-tight text-gray-900 mb-3">My Courses</h2>
                    <div className='mc_tabHolder'>
                        <div value={activeTab} >
                            <ul className="flex sm:flex-none sm:w-full rounded-none border-b border-blue-gray-50 bg-transparent p-0"
                                // indicatorProps={{
                                // className:
                                //     "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
                                // }}
                            >
                                {data.map(({ label, value }) => {
                                
                                return <li
                                    key={value}
                                    value={value}
                                    onClick={() => setActiveTab(value)}
                                    className={`mc_tabList ${activeTab == value ? "color-themeColor" : ""}`}
                                >
                                    <a href={() => false} className='mc_listItem_dv'>{label} [{cources.length}] </a>
                                </li>}
                                )}
                            </ul>
                            <div className='mt-8'>
                                <div  className='px-0'>
                                    <h4 className='text-lg text-black mt-3 font-bold'>{activeTab == 'MyLearnings' ? 'Course In-Progress' : (activeTab == 'CompletedCourses' ? 'Completed Courses' : ( activeTab == 'CoursesLibrary' ? '': 'My Wishlist'))}</h4>
                                    <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8">
                                        {cources.length  && !state.pageLoading ?  cources.map((items, index)=>(
                                            activeTab == 'MyLearnings' && items.progress != '100' ?
                                            <div key={index} className="group relative bg-white box-shadow rounded-md mc_courseBox">  
                                                                                            
                                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded bg-gray-300 lg:aspect-none group-hover:opacity-75 lg:h-60 mc_courseImg">
                                                    <img
                                                        src={'/assets/images/Course1.jpg'}
                                                        alt={'img'}
                                                        className="h-full w-full rounded-md object-cover object-center lg:h-full lg:w-full"
                                                    />
                                                    
                                                </div>
                                                <div className="mt-0 mc_courseContent">
                                                    <div>
                                                        <h3 className="text-base text-black font-extrabold">
                                                            <Link to={items.href}>
                                                                {items.title}
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                    {/* <h5>{value+index}</h5> */}
                                                    <p className='mt-4 text-sm text-black'>Progress</p>
                                                    <Progress  percent={items.progress} />
                                                    <p className="mb-4 text-gray-900 text-xs">{items.modules} | {items.endDateTime}</p>
                                                    <div className='mc_batchHolder flex center'>
                                                        <span className='mc_batch mr-3'>{svg.app.batchIcon}</span>
                                                        <span className='text-xs text-gray-500'>Total Points : <b className='text-xs text-black font-semibold'>{items.points}</b></span>
                                                    </div>
                                                </div>
                                                <div className='mc_courseList_data'>
                                                    <p className="py-5 px-5 items-center text-sm font-medium text-gray-900 mc_dataActive flex justify-between mc_border">
                                                        <span className='mc_listIten'>Resent Activities</span>
                                                        <span className='mc_bgBtn'>{items.activities}</span>
                                                    </p>
                                                    <p className="py-5 px-5 text-sm font-medium text-gray-900 mc_points flex items-center justify-between mc_border">
                                                        <span className='mc_listIten text-sm'>Total Students Enrolled</span>
                                                        <span className='mc_bgBtn'>{items.points}</span>
                                                    </p>
                                                </div>
                                                <a href={() => false} className='mc_btn mc_courseBtn text-sm' onClick={(e)=> navigate('/my-learning')}>
                                                    Continue Learning 
                                                    <span>{svg.app.arrowRight}</span>
                                                </a>
                                            </div>
                                            : 
                                            (activeTab == 'CompletedCourses' && items.progress == '100' ? 
                                                <div key={index} className="group relative bg-white box-shadow  rounded-md"> 
                                                    <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded bg-gray-300 lg:aspect-none group-hover:opacity-75 lg:h-60 mc_courseImg">
                                                        <img
                                                            src={"/assets/images/Course1.jpg"}
                                                            alt={'items'}
                                                            className="h-full w-full rounded-md object-cover object-center lg:h-full lg:w-full"
                                                        />
                                                    </div>
                                                    <div className="mt-0 mc_courseContent">
                                                        <div >
                                                            <h3 className="text-base text-gray-900 font-extrabold mc_headingText">
                                                                <Link to={items.href}>
                                                                    {items.title}
                                                                </Link>
                                                            </h3>
                                                        </div>
                                                        {/* <h5>{value+index}</h5> */}
                                                        <p className='mt-4 text-sm text-black relative'>
                                                            Progress
                                                            {items.progress == '100' ? 
                                                                <span className='mc_persentage text-xs '>{`[${items.progress}]`}%</span>
                                                                :''
                                                            }
                                                        </p>
                                                        {/* <div className='mc_progressHolder mt-4'>
                                                            <div className="mb-0 flex items-center justify-between gap-4">
                                                                <p className='text-xs font-semibold text-black'>{state.currentModules==state.modules ? '100' : Math.round(100 / state.modules)*state.currentModules}%</p>
                                                                <p className='text-xs font-semibold text-black'>{state.currentModules}/6 Modules</p>
                                                            </div>
                                                            <Progress percent={state.currentModules==state.modules ? '100' : Math.round(100 / state.modules)*state.currentModules}/>
                                                        </div> */}
                                                        <Progress  percent={items.progress} />

                                                        <p className="mb-4 text-gray-900 text-xs text-black">{items.modules} | {items.endDateTime}</p>
                                                        <div className='mc_batchHolder flex items-center'>
                                                            <span className='mc_batch mr-3'>{svg.app.batchIcon}</span>
                                                            <span className='text-xs text-gray-500'>Total Points : <b className='text-xs text-black font-semibold'>{items.points}</b></span>
                                                        </div>
                                                    </div>
                                                    <div className='mc_courseList_data'>
                                                        <p className="py-5 px-5 text-sm font-medium text-gray-900 mc_dataActive flex items-center justify-between mc_border">
                                                            <span className='mc_listIten'>Your Assessment score</span>
                                                            <span className='mc_bgBtn mc_bgGreen'>{items.yourScore}%</span>
                                                        </p>
                                                        <div className="py-5 px-5 text-sm font-medium text-gray-900 mc_points flex items-center justify-between mc_border">
                                                            <span className='mc_listIten'>Share Your Certificate With</span>
                                                            {/* <span className='mc_bgBtn'>{items.points}</span> */}
                                                            
                                                        
                                                            <div className="flex gap-1">
                                                                {sharedSocialUrl.map((items)=>{
                                                                    return  <Link key={items.id} to='/'>
                                                                        <img src={items.icon} alt="socialicon" width={'24px'} height={'24px'}/>
                                                                    </Link>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link className='mc_btn mc_courseBtn text-sm' to='/my-learning'>
                                                        Download Your Certificate
                                                        <span>{svg.app.arrowRight}</span>
                                                    </Link>
                                                </div>
                                            : '')
                                        ))
                                        : 
                                        <NoDataWrapper 
                                            className="group relative bg-white box-shadow rounded-md mc_courseBox" 
                                            blockCount="4" 
                                            height="340"
                                            width="291" 
                                            section="postGrid"
                                            // dataCount={cources.length}
                                            // title={state.keyword != '' ?'Project not found.' : 'You have not created any project yet.'} 
                                            // subTitle={state.keyword != '' ?'' : 'Feel free to create new project by clicking on New Project button at top right corner.'} 
                                        />
                                    }
                                    
                                    </div>
                                    {
                                        activeTab == 'CoursesLibrary' ? <>
                                        <div className='mc_libraryData'>
                                            <div className='mc_courseList'>
                                                <div value="allCourses" orientation="vertical" className="overflow-hidden flex mc_navTab">
                                                    <nav>
                                                        <ul className="bg-blue-gray-50 bg-opacity-60 rounded-lg p-1 w-full flex relative flex-col">
                                                            {coursesData.map(({ label, value }, i ) => (
                                                            <li key={i} className={`items-center justify-center text-center h-full bg-transparent py-1 px-2 text-blue-gray-900 antialiased font-sans text-base font-normal leading-relaxed select-none cursor-pointer w-full flex relative flex-col ${innerActiveTab == value ? 'tab_active' : ''}`} onClick={e => setInnerActiveTab(value)}>
                                                                {label} [{cources.length}]
                                                            </li>
                                                            ))}
                                                        </ul>
                                                    </nav>
                                                    <div>
                                                        <div className="py-5 pl-12 pr-0">
                                                            {innerActiveTab == 'allCourses' ? 
                                                                <div className='mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8'>
                                                                    {state.libraryData.map((t, i )=>{
                                                                        return <div key={i} className="w-full bg-white rounded-lg mc_courseBox ">
                                                                            <div color="blue-gray" className="relative h-44">
                                                                                <img
                                                                                src={"/assets/images/libraryimg1.jpg"}
                                                                                alt="cardimage"
                                                                                className='h-full w-full'
                                                                                />
                                                                            </div>
                                                                            <div className='p-5 mc_content relative'>
                                                                                <a href={() => false} className='mc_whishList_icon'onClick={()=>{
                                                                                        let data = [...state.libraryData];
                                                                                        data[i].whishList = (data[i].whishList == false ? true : false);
                                                                                        console.log(data[i].whishList, 'data[i].whishList');
                                                                                        setMyState(setQuery,{
                                                                                        ...state,
                                                                                        libraryData : data,
                                                                                        })
                                                                                    }}>{t.whishList==true ? svg.app.heartIcon : svg.app.heartBordered}</a>
                                                                                <p className='text-xs text-gray-400 mb-4'>{t.highlights}</p>
                                                                                <Link to={'/course-details'} className="mb-2 text-gray-900 text-base font-bold mc_headingText">
                                                                                    {t.title}  
                                                                                </Link>
                                                                                <div className='mc_studentContent'>
                                                                                    <p className='mt-3 text-xs text-gray-700'>{t.students} students . {t.totalTime}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    )}
                                                                </div>
                                                            : innerActiveTab == 'TrendingCourses' ? 
                                                                <div className='mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8'>
                                                                        {state.libraryData.map((t, i )=>{
                                                                        return <div key={i+11} className="w-full bg-white rounded-lg mc_courseBox ">
                                                                            <div color="blue-gray" className="relative h-44">
                                                                                <img
                                                                                src={"/assets/images/libraryimg1.jpg"}
                                                                                alt="cardimage"
                                                                                className='h-full w-full'
                                                                                />
                                                                            </div>
                                                                            <div className='p-5 mc_content relative'>
                                                                                <a href={() => false} className='mc_whishList_icon'onClick={()=>{
                                                                                        let data = [...state.libraryData];
                                                                                        data[i].whishList = (data[i].whishList == false ? true : false);
                                                                                        console.log(data[i].whishList, 'data[i].whishList');
                                                                                        setMyState(setQuery,{
                                                                                        ...state,
                                                                                        libraryData : data,
                                                                                        })
                                                                                    }}>{t.whishList==true ? svg.app.heartIcon : svg.app.heartBordered}</a>
                                                                                <p className='text-xs text-gray-400 mb-4'>{t.highlights}</p>
                                                                                <Link to={'/course-details'} a className="mb-2 text-gray-900 text-base font-bold mc_headingText">
                                                                                    {t.title}  
                                                                                </Link>
                                                                                <div className='mc_studentContent'>
                                                                                    <p className='mt-3 text-xs text-gray-700'>{t.students} students . {t.totalTime}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    )}
                                                                    </div> 
                                                            : innerActiveTab == 'MostPurchased' ? 
                                                                <div className='mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8'>
                                                                        {state.libraryData.map((t, i )=>{
                                                                        return <div key={i+11} className="w-full bg-white rounded-lg mc_courseBox ">
                                                                            <div color="blue-gray" className="relative h-44">
                                                                                <img
                                                                                src={"/assets/images/libraryimg1.jpg"}
                                                                                alt="cardimage"
                                                                                className='h-full w-full'
                                                                                />
                                                                            </div>
                                                                            <div className='p-5 mc_content relative'>
                                                                                <a href={() => false} className='mc_whishList_icon'onClick={()=>{
                                                                                        let data = [...state.libraryData];
                                                                                        data[i].whishList = (data[i].whishList == false ? true : false);
                                                                                        console.log(data[i].whishList, 'data[i].whishList');
                                                                                        setMyState(setQuery,{
                                                                                        ...state,
                                                                                        libraryData : data,
                                                                                        })
                                                                                    }}>
                                                                                    {t.whishList==true ? svg.app.heartIcon : svg.app.heartBordered}
                                                                                </a>
                                                                                <p className='text-xs text-gray-400 mb-4'>{t.highlights}</p>
                                                                                <Link to={'/course-details'} a className="mb-2 text-gray-900 text-base font-bold mc_headingText">
                                                                                    {t.title}  
                                                                                </Link>
                                                                                <div className='mc_studentContent'>
                                                                                    <p className='mt-3 text-xs text-gray-700'>{t.students} students . {t.totalTime}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    )}
                                                                    </div>
                                                            : innerActiveTab == 'MostViewed' ? 
                                                                <div className='mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8'>
                                                                    {libraryData.map((t, i )=>{
                                                                        return <div key={i+11} className="w-full bg-white rounded-lg mc_courseBox ">
                                                                            <div color="blue-gray" className="relative h-44">
                                                                                <img
                                                                                src={"/assets/images/libraryimg1.jpg"}
                                                                                alt="cardimage"
                                                                                className='h-full w-full'
                                                                                />
                                                                            </div>
                                                                            <div className='p-5 mc_content relative'>
                                                                                <a href={() => false} className='mc_whishList_icon'>{t.whishList==true ? svg.app.heartIcon : svg.app.heartBordered}</a>
                                                                                <p className='text-xs text-gray-400 mb-4'>{t.highlights}</p>
                                                                                <Link to={'/course-details'} className="mb-2 text-gray-900 text-base font-bold mc_headingText">
                                                                                    {t.title}  
                                                                                </Link>
                                                                                <div className='mc_studentContent'>
                                                                                    <p className='mt-3 text-xs text-gray-700'>{t.students} students . {t.totalTime}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    )}
                                                                </div>
                                                            : innerActiveTab == 'MostLiked' ? 
                                                                <div className='mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8'>
                                                                    {state.libraryData.map((t, i )=>{
                                                                        return <div key={i+11} className="w-full bg-white rounded-lg mc_courseBox ">
                                                                            <div color="blue-gray" className="relative h-44">
                                                                                <img
                                                                                src={"/assets/images/libraryimg1.jpg"}
                                                                                alt="cardimage"
                                                                                className='h-full w-full'
                                                                                />
                                                                            </div>
                                                                            <div className='p-5 mc_content relative'>
                                                                                <a href={() => false} className='mc_whishList_icon' onClick={()=>{
                                                                                        let data = [...state.libraryData];
                                                                                        data[i].whishList = (data[i].whishList == false ? true : false);
                                                                                        console.log(data[i].whishList, 'data[i].whishList');
                                                                                        setMyState(setQuery,{
                                                                                        ...state,
                                                                                        libraryData : data,
                                                                                        })
                                                                                    }}>{t.whishList==true ? svg.app.heartIcon : svg.app.heartBordered}</a>
                                                                                <p className='text-xs text-gray-400 mb-4'>{t.highlights}</p>
                                                                                <Link to={'/course-details'} a className="mb-2 text-gray-900 text-base font-bold mc_headingText">
                                                                                    {t.title}  
                                                                                </Link>
                                                                                <div className='mc_studentContent'>
                                                                                    <p className='mt-3 text-xs text-gray-700'>{t.students} students . {t.totalTime}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    )}
                                                                </div>
                                                            : ''
                                                            }
                                                            <div className='mc_viewMoreBtn w-full text-center center mt-12'>
                                                                <a href={() => false} className='mc_btnViewMore text-lg text-black font-bold text-center'>View More Courses</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                                                        
                                    </>
                                    : activeTab == 'Wishlist' ?
                                    <>
                                        <div className='mt-2 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-5 lg:grid-cols-4  xl:gap-x-8'>
                                            {state.libraryData.map((t, i )=>{
                                                
                                                if(t.whishList == true){
                                                    return (
                                                        <div key={i+1} className="w-full bg-white rounded-lg mc_courseBox ">
                                                            <div color="blue-gray" className="relative h-44">
                                                                <img
                                                                src={"/assets/images/libraryimg1.jpg"}
                                                                alt="cardimage"
                                                                className='h-full w-full'
                                                                />
                                                            </div>
                                                            <div className='p-5 mc_content relative'>
                                                                <a href={() => false} className='mc_whishList_icon' onClick={()=>{
                                                                    let data = [...state.libraryData];
                                                                    data[i].whishList = (data[i].whishList == false ? true : false);
                                                                    console.log(data[i].whishList, 'data[i].whishList');
                                                                    setMyState(setQuery,{
                                                                    ...state,
                                                                    libraryData : data,
                                                                    })
                                                                }}>{t.whishList==true ? svg.app.heartIcon : svg.app.heartBordered}</a>
                                                                <p className='text-xs text-gray-400 mb-4'>{t.highlights}</p>
                                                                <Link to={'/course-details'} className="mb-2 text-gray-900 text-base font-bold mc_headingText">
                                                                    {t.title}  
                                                                </Link>
                                                                <div className='mc_studentContent'>
                                                                    <p className='mt-3 text-xs text-gray-700'>{t.students} students . {t.totalTime}</p>
                                                                </div>
                                                                <div className='mc_price text-black mt-3 '>{t.price}</div>
                                                            </div>
                                                            <a href={() => false} className='mc_btn mc_courseBtn text-sm font-medium'>Buy Now
                                                                <span>{svg.app.arrowRight}</span>
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            }
                                            )}
                                            
                                        </div>
                                        {
                                            state.libraryData.filter((w) => w.whishList === true).length === 0 ? 
                                                <NoDataWrapper 
                                                    className="w-full bg-white rounded-lg mc_courseBox " 
                                                    blockCount="1" 
                                                    height="340"
                                                    width="291" 
                                                    section="blocks"
                                                    dataCount={state.libraryData.whishList}
                                                    // title={state.keyword != '' ?'Library Data not found.' : 'You have no Library Data yet.'} 
                                                />
                                            : ''
                                            }
                                    </>
                                    : ''
                                     
                                    
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MyCourses;
